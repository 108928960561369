/* Styles go here */

body {
  color: white;
  background-color: rgb(23, 27, 31);
  overflow-x: hidden;
}


#bigBanner {
	background-color: rgb(220, 53, 69);
	height: 60px;
	color: white;
	text-align: center;
	padding: 17px;
}

#warning-card {
	background-color: #EEE4E4;
}

.color-nav{
	background-color: white;
}

#whiteSimpleText {
	color: white;
	text-decoration: none;
}

.text-black {
	color: black;
}

.horizontal-gradient {
  background: linear-gradient(to right, rgb(17, 237, 237), rgb(224, 16, 214));
  text-align: center;
  font-size: 1.3rem;
}

.text-gradient {
	background: linear-gradient(to right, rgba(0,252,254,255), rgba(128,129,133,255));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

#footer {
	background: linear-gradient(to right, rgb(0, 209, 255), rgb(237, 30, 255));
	text-align: center;
	left: 0px;
	bottom: 0px;
	height: 200px;
	width: 100%;
	color: white;
	font-size: 1.3rem;
}

/***************************************/

.disable-me {
    pointer-events: none;
}

.dot
{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: inline-block; 
}

#hrefWhite {
	color: white;
}

.title {
	font-weight: 900;
	font-size: 5rem;
	line-height: 95%;
	letter-spacing: .5%;
	margin-top: 50px;
}

.socialMedia {
	margin-bottom: 80px;
	margin-top: 10px;
}

.myIntro {
	font-size: 2rem;
}

.iframe-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
}
.iframe-container iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 75%;
}

.light-grey {
	background-color: rgba(250,251,255,255);
}

ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 90%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 40px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

li.mylist {
    list-style-type: none;
}

p span {
  display: block;
}

.rtitle {
  color: #0056b3;
  font-size: 30px;
}

.roadmap-card {
    border-radius: 2%;
    border: 3px solid #22c0e8;
}

.disabled-link {
  pointer-events: none; /* Prevents clicking */
  color: gray; /* Makes the link look disabled */
}

/* Global image styling with rounded corners, excluding the main logo */
img:not(#DGN_logo) {
  border-radius: 10px; /* Adjust as needed */
}

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');


/* Global font settings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Press Start 2P', cursive;
  font-size: 72px; /* Adjust size to match the design */
  line-height: 80px; /* Adjust line height to match the design */
  margin: 0 0 20px 0;
  font-weight: bold; /* Adjust weight as needed */
  text-shadow: -3px 5px 0 rgba(0, 0, 0, 1); /* Sharp shadow for a bold look */
  color: #D3D3D3; /* Light grey color */
}

/* Adjusting h5 font size */
h5, h3 {
  font-size: 16px; /* Reduced from a larger size for a more balanced appearance */
  line-height: 24px; /* Adjust line height accordingly for readability */
  margin-bottom: 15px; /* Adjust spacing as needed */
  color: inherit; /* Keeps the font color consistent with the parent element, or specify a color */
}


/* Applying the font family from Google Fonts (if you decided to use Roboto or Montserrat) */
body, button, input, textarea {
  font-family: 'Roboto', 'Montserrat', sans-serif;
}

/* Additional styles for hover effects on interactive elements */
button:hover, a:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease-in-out;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  h1 {
    font-size: 36px; /* Smaller font size for mobile devices */
    line-height: 56px;
  }
}

@media (max-width: 768px) {
  h5 {
    font-size: 12px; /* Slightly smaller on mobile devices */
    line-height: 20px;
  }
}


/* Override for Roadmap sections */
.roadmap-card .rtitle {
  font-family: 'Roboto', 'Montserrat', sans-serif; /* Switch back to a more standard font for readability */
  font-size: 24px; /* Adjust based on your design needs */
  line-height: 32px;
  color: #000; /* Default color for visibility */
  text-shadow: none; /* Remove shadow for these sections */
}

.roadmap-card {
  color: #000; /* Sets the text color to black */
}

/* General styles for images */
.fight-gif,
.characters-img {
  border-radius: 30px !important;/* Apply rounded corners */
}

/* Media query for screens smaller than 768px (typical breakpoint for mobile devices) */
@media (max-width: 768px) {
  .fight-gif,
  .characters-img {
    max-width: 100%; /* Allow images to fill the container on smaller screens */
    padding: 10px 0; /* Adjust padding for smaller screens */
  }
}